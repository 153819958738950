<script setup>
const { state, onConfirm, onCancel } = useConfirmDialogStore()

const keys = useMagicKeys({ reactive: true })

whenever(() => keys.enter && state.visible, onConfirm)
whenever(() => keys.escape && state.visible, onCancel)
</script>

<template>
  <v-dialog v-model="state.visible" :max-width="state.width || 500" persistent>
    <v-card>
      <v-toolbar :title="state.title" :color="state.color" density="compact">
        <v-btn icon="mdi-close" @click.stop="onCancel" />
      </v-toolbar>
      <v-card-text>
        <v-row align="center" justify="center">
          <v-col sm="2" align="center">
            <v-icon :color="state.color" :icon="state.icon" size="45" />
          </v-col>
          <v-col>
            {{ state.message }}
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider class="mt-1" />
      <v-card-actions>
        <v-spacer />
        <v-btn prepend-icon="mdi-close" variant="text" @click.stop="onCancel">
          No
        </v-btn>
        <v-btn :color="state.color" prepend-icon="mdi-check" variant="text" @click.stop="onConfirm">
          Yes
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
